@tailwind base;
@tailwind components;
@tailwind utilities;

body,
p {
  margin: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "HelloKidmeOtf";
  src: url("./assets/fonts/HelloKidme.otf") format("otf");
  font-style: normal;
}

.shadow-text {
  text-shadow: -8px 8px 0px rgba(0, 0, 0, 0.2);
  letter-spacing: -0.04em;
}
.shadow-custom {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1), -10px 12px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow-right {
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.5);
}
.box-shadow-left {
  box-shadow: -5px 8px 0px 0px rgba(0, 0, 0, 0.2);
}
.box-shadow-card {
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.1);
}
.transition-3s {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.text-shadow-left {
  text-shadow: -4px 5px 0px rgba(0, 0, 0, 0.2);
}
.footer-info a {
  color: #fff;
}

.slide {
  margin: 0 0 0 30px;
}
.slick-slide img {
  margin-left: 0px;
}
.slick-slider {
  /* max-width: 1220px; */
}
.slick-dots {
  bottom: -40px !important;
}
.slick-dots li button:before {
  color: #ffffff !important;
  font-size: 13px !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  color: #55cfff !important;
}
.slick-dots.active-dot {
  left: -650px;
}
.slider-static .slick-slide img {
  margin-left: 0;
}

.slider-static .slick-prev {
  left: 0;
}
.slider-static .slick-next {
  right: 0;
}

.slider-renders .slick-dots li.slick-active button:before {
  color: #000 !important;
}
.slider-renders .slick-dots li button:before {
  font-size: 7px !important;
  color: #9c9797 !important;
}

.elementor-animation-pop a {
  font-weight: 900;
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.5);
  width: max-content;
}
.heart-icon {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

a:hover .heart-icon {
  fill: red;
}
.elementor-animation-pop a:hover {
  animation: elementor-animation-pop 0.6s ease-in-out;
  color: #e01217;
  background-color: #ffffff;
}
.elementor-animation-float {
  transition: all 0.3s;
}
.elementor-animation-float:hover {
  transform: translateY(-8px);
}

.footer-static p {
  margin: 0 0 10px 0;
}
.footer-static a {
  color: #ffffff;
  text-decoration: none;
}

.custom-radio-group {
  display: flex;
  gap: 20px;
}

.custom-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  transition: background-color 0.3s ease, border 0.3s ease;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  padding: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio.selected {
  outline: solid 6px #e30613;
}

.custom-radio:not(.selected):hover {
  background-color: rgba(227, 6, 19, 0.2);
}

.bg-overlay-title-transparent {
  background-image: url(./assets/images/bg-overlay-titles.webp);
}
.bg-overlay-title-blue {
  background-image: url(./assets/images/bg-overlay-titles-blue.webp);
}
.bg-overlay-title-red {
  background-image: url(./assets/images/bg-overlay-titles-red.webp);
}
.bg-overlay-title-green {
  background-image: url(./assets/images/bg-overlay-titles-green.webp);
}
.bg-overlay-title-pink {
  background-image: url(./assets/images/bg-overlay-titles-pink.webp);
}
.bg-overlay-title {
  background-size: 34px;
  background-position: left top;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 20px;
}

.text-html p {
  line-height: 170%;
  margin-bottom: 20px;
}
.text-html p strong {
  display: inline-block;
  word-break: break-word;
  white-space: normal;
}
.text-html ul li {
  margin-bottom: 10px;
}

.text-html-news p {
  line-height: 150%;
  margin-bottom: 20px;
}
.text-html-news ul li {
  margin-bottom: 10px;
}

.text-html-news iframe {
  width: 100%;
  height: 650px;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes elementor-animation-pop {
  50% {
    transform: scale(1.2);
  }
}

@media (max-width: 768px) {
  .slide {
    margin: 0;
  }
  .slick-slider {
    max-width: 100%;
  }
  .slick-dots.active-dot {
    left: 0;
  }
  .slick-slide img {
    margin-left: 18px;
  }
  .shadow-text {
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .floating-button.box-shadow-left {
    box-shadow: -5px 3px 0px 0px rgba(0, 0, 0, 0.2);
  }
  .slider-static.slider-container {
    width: auto !important;
  }
  .custom-radio-group {
    display: block;
  }
  .custom-radio {
    margin-bottom: 15px;
  }
  .slider-renders .slick-slide img {
    margin-left: 0;
  }
  .text-html-news iframe {
    width: 100%;
    height: 250px;
  }
  .text-html-news img {
    width: 100%;
  }
}

/* Aplica el color a los dots de slick */
.home-historias-video-slick .slick-dots li button::before {
  color: #dadada !important;
  opacity: 0.5;
}

/* Cambia el color de los dots activos */
.home-historias-video-slick .slick-dots li.slick-active button::before {
    color: #e30613 !important;
    opacity: 1;
  }

  .home-banner-slick .slick-next, .home-banner-slick .slick-prev {
    z-index: 99 !important;
    display: none !important;

    @screen md {
      display: inline-block !important;
    }
  }

  .home-banner-slick .slick-prev {
    left: 25px !important;
  }

  .home-banner-slick .slick-next {
    right: 65px !important;
  }

  .home-banner-slick .slick-prev::before, .home-banner-slick .slick-next::before {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    .home-banner-slick .slick-slide img {
        margin-left: 0 !important;
    }
}
